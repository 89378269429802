export function loadSave() {
    return localStorage.getItem("save")
}

/*
player data:
- money
- exprience

cow data:
- list of cows
    - cow id
    - time full timestamp
    - type
    - rgb


*/
export function createSave(playerData, cowData, factoryData){
    localStorage.setItem("save", )
}